<template>
  <div id='loader-container'>
    <b-spinner></b-spinner>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
  #loader-container{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16px;
    color: var(--main-color);
  }
</style>
